import React from 'react';
import Main from '../../containers/Layout';
import wholesalersPageContent from '../../../content/pages/marketing/wholesalers-logistic.yml';
import WholesalersLogisticContainer from '@/containers/WholesalersLogistic';

export interface WholesalersPageContent {
  viewport1: {
    title1: string;
    title2: string;
    title3: string;
    description1: string;
    description2: string;
    descriptionItems: {
      item: string;
    }[];
    appStoreButton: {
      url: string;
    };
    googlePlayButton: {
      url: string;
    };
  };
  viewport2: {
    label: string;
    benefitCards: {
      label: string;
      text: string;
      image: string;
    }[];
  };
  viewport3: {
    label: string;
    leftCardLabel1: string;
    leftCardLabel2: string;
    leftCardText: string;
    rightCardLabel1: string;
    rightCardLabel2: string;
    rightCardText: string;
  };
  viewport4: {
    label: string;
    description: string;
    payByLinkCards: {
      count: string;
      label: string;
      text: string;
      image: string;
    }[];
  };
  viewport5: {
    title1: string;
    title2: string;
    boldDescription1: string;
    boldDescription2: string;
    boldDescription3: string;
    description1: string;
    description2: string;
    appStoreButton: {
      url: string;
    };
    googlePlayButton: {
      url: string;
    };
  };
  viewport6: {
    title: string;
    howItWorksCards: {
      title: string;
      text: string;
      image: string;
    }[];
  };
  viewport7: {
    label: string;
    title1: string;
    title2: string;
    description1: string;
    description2: string;
    descriptionItems: {
      item: string;
    }[];
  };
  viewport8: {
    title1: string;
    title2: string;
    title3: string;
    description: string;
    descriptionItems: {
      item: string;
    }[];
  };
  viewport9: {
    title: string;
    description: string;
    howToCards: {
      count: string;
      text: string;
      image: string;
    }[];
  };
  viewport10: {
    bannerLabel: string;
    descriptionItems: {
      item: string;
    }[];
    button: { label: string; url: string };
  };
}
const WholesalersLogistic: React.FunctionComponent = () => {
  const {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
    viewport8,
    viewport9,
    viewport10,
  } = wholesalersPageContent as unknown as WholesalersPageContent;
  return (
    <Main>
      <WholesalersLogisticContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
          viewport6,
          viewport7,
          viewport8,
          viewport9,
          viewport10,
        }}
      />
    </Main>
  );
};

export default WholesalersLogistic;
