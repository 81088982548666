import React from 'react';
import styled from 'styled-components';
import FirstViewport from '@/components/FirstViewport';
import {
  Description,
  H1,
  H3,
  P,
  SecondaryP,
  StrongInitial,
  Subtitle2,
} from '@/components/Typography';
import LinkIcon from '../assets/icons/link.svg';
import RefreshIcon from '../assets/icons/refresh.svg';
import Vp1Image from '../assets/img/wholesalersVp1.png';
import Vp5Image from '../assets/img/wholesalersVp5.png';
import Vp6Image from '../assets/img/accountantsCode.png';
import Vp7Image from '../assets/img/accountantsVp5.png';
import invoiceCardLeft from '../assets/img/invoiceCardLeft.png';
import invoiceCardRight from '../assets/img/invoiceCardRight.png';
import CarouselCard from '@/components/CarouselCard';
import Section from '@/components/Section';
import PayByLinkCard from '@/components/PayByLinkCard';
import FirstViewportMirrored from '@/components/FirstViewportMirrored';
import HowToCard from '@/components/HowToCard';
import Banner from '@/components/Banner';
import bannerBg from '../assets/img/accountantsBanner.png';
import TickedItems from '@/components/TickedItems';
import { WholesalersPageContent } from '@/pages/marketing/wholesalers-logistic';
import ButtonDefault from '@/components/ButtonDefault';
import GooglePlayBg from '../assets/icons/googlePlayBg.svg';
import AppleStoreBg from '../assets/icons/appStoreBg.svg';
import HowItWorksCard from '@/components/HowItWorksCard';

const FirstViewportWrapper = styled.div`
  background: linear-gradient(123.46deg, #fbfbfd 45.14%, #e7edf3 100.49%);
`;

const VpWrapper = styled.div`
  background: linear-gradient(
    184deg,
    rgba(244, 246, 249, 1) 0%,
    rgba(251, 252, 253, 1) 40%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const DescriptionWrapper = styled.div`
  margin: 2rem 0;
`;

const RedSpan = styled.span`
  text-transform: uppercase;
  color: #ef6355;
`;

const Buttons = styled.div`
  margin-top: 1rem;
  /* display: none; */
`;

const ViewportsWrapper = styled.div`
  background: linear-gradient(
    31.87deg,
    rgba(255, 255, 255, 0.2) 20.76%,
    rgba(228, 230, 240, 0.2) 82.32%
  ); ;
`;

const FifthViewport = styled(FirstViewport)``;

const SecondViewport = styled(Section)`
  background-color: #ffff;
  padding-bottom: 0;
`;

const CardWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    flex-wrap: wrap;
  }
`;

const Label = styled(H3)`
  text-align: center;
  @media (max-width: 1200px) {
    text-align: left;
  }
`;

const ThirdViewport = styled(Section)`
  background-color: #ffff;
  padding-bottom: 0;
`;

const InvoiceCardsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 2rem;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const HowItWorksCardWrap = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-wrap: nowrap;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const InvoiceCard = styled.div`
  border-radius: 20px;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 5rem;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 1200px) {
    margin-bottom: 2rem;
    flex-grow: 1;
    flex-basis: 100%;
    margin-right: 0;
  }
`;

const InvoceCardLabel = styled(Subtitle2)`
  margin: 1rem 0;
`;

const InvoiceCardLeft = styled(InvoiceCard)`
  background: linear-gradient(341.28deg, #c2cedb -38.66%, #f4f7f9 130.75%);
`;
const InvoiceCardRight = styled(InvoiceCard)`
  background: linear-gradient(128.69deg, #14bd89 11.83%, #47ef90 86.41%);
`;

const InvoiceCardImg = styled.img`
  align-self: flex-end;
  width: 80%;
  @media (max-width: 1200px) {
    border-bottom-right-radius: 20px;
  }
`;

const InvoiceCardTop = styled.div`
  padding: 3rem 3rem 0 3rem;
  @media (max-width: 1200px) {
    padding: 1rem 1rem 0 1rem;
  }
`;

const FourthViewport = styled(Section)`
  background-color: #fff;
`;

const VpDescription = styled(P)`
  text-align: center;
  margin-top: 1rem;
  @media (max-width: 1200px) {
    text-align: left;
  }
`;

const PayByLinkCardsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
`;

const StyledStrong = styled(StrongInitial)`
  margin-top: 2rem;
`;

const SixthViewport = styled(Section)`
  padding-top: 0;
  background-color: #fff;
`;

const SeventhViewport = styled(Section)`
  background-color: #fff;
`;

const CenterH1 = styled(H1)`
  text-align: center;
  @media (max-width: 1200px) {
    text-align: left;
  }
`;

const HowToCardsWrap = styled.div`
  display: flex;
  margin-top: 2rem;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const EighthViewport = styled(Section)`
  background-color: #fff;
`;

const VpWrapperWhite = styled.div`
  background-color: #fff;
`;

interface WholesalersProps {
  content: WholesalersPageContent;
}

const WholesalersLogisticContainer: React.FunctionComponent<
  WholesalersProps
> = ({
  content: {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
    viewport8,
    viewport9,
    viewport10,
  },
}) => (
  <>
    <FirstViewportWrapper>
      <FirstViewport withRightPadding img={Vp1Image}>
        <H1>
          {viewport1.title1}
          {` `}
          <span className="accent-text">{viewport1.title2}</span>
          {` `}
          {viewport1.title3}
        </H1>
        <StyledStrong>
          {viewport1.description1}
          {` `}
          <RedSpan> {viewport1.description2}</RedSpan>
        </StyledStrong>
        <DescriptionWrapper>
          <TickedItems items={viewport1.descriptionItems} />
        </DescriptionWrapper>
        <Buttons>
          <ButtonDefault withImage to={viewport1.googlePlayButton.url}>
            <GooglePlayBg />
          </ButtonDefault>
          {` `}
          <ButtonDefault withImage to={viewport1.appStoreButton.url}>
            <AppleStoreBg />
          </ButtonDefault>
        </Buttons>
      </FirstViewport>
    </FirstViewportWrapper>
    <ViewportsWrapper>
      <SecondViewport>
        <Label>{viewport2.label}</Label>
        <CardWrapper>
          {viewport2.benefitCards.map((item) => (
            <CarouselCard
              key={item.label}
              flexBasis="25%"
              marginBottom="2rem"
              title={item.label}
              text={item.text}
              cardImgSrc={item.image}
            />
          ))}
        </CardWrapper>
      </SecondViewport>
    </ViewportsWrapper>
    <ThirdViewport>
      <Label>{viewport3.label}</Label>
      <InvoiceCardsWrapper>
        <InvoiceCardLeft>
          <InvoiceCardTop>
            <RefreshIcon />
            <InvoceCardLabel>
              {viewport3.leftCardLabel1}
              {` `}
              <span className="accent-text">{viewport3.leftCardLabel2}</span>
            </InvoceCardLabel>
            <P>{viewport3.leftCardText}</P>
          </InvoiceCardTop>

          <InvoiceCardImg src={invoiceCardLeft} alt="code screenshot" />
        </InvoiceCardLeft>
        <InvoiceCardRight>
          <InvoiceCardTop>
            <LinkIcon />
            <InvoceCardLabel>
              <span className="accent-text-white">
                {viewport3.rightCardLabel1}
              </span>
              {` `}
              {viewport3.rightCardLabel2}
            </InvoceCardLabel>
            <P className="accent-text-white">{viewport3.rightCardText}</P>
          </InvoiceCardTop>

          <InvoiceCardImg src={invoiceCardRight} alt="code screenshot" />
        </InvoiceCardRight>
      </InvoiceCardsWrapper>
    </ThirdViewport>
    <FourthViewport>
      <Label>{viewport4.label}</Label>
      <VpDescription>{viewport4.description}</VpDescription>
      <PayByLinkCardsWrap>
        {viewport4.payByLinkCards.map((item) => (
          <PayByLinkCard
            key={item.label}
            number={item.count}
            label={item.label}
            text={item.text}
            imageSrc={item.image}
          />
        ))}
      </PayByLinkCardsWrap>
    </FourthViewport>
    <VpWrapper>
      <FifthViewport imgWidth="50%" withRightPadding img={Vp5Image}>
        <H1>
          <span className="accent-text">{viewport5.title1}</span>
          {` `}
          {viewport5.title2}
        </H1>
        <StyledStrong>
          {viewport5.boldDescription1}
          {` `}
          <RedSpan>{viewport5.boldDescription2}</RedSpan>
          {` `}
          {viewport5.boldDescription3}
        </StyledStrong>
        <Description>{viewport5.description1}</Description>
        <Description>{viewport5.description2}</Description>
        <Buttons>
          <ButtonDefault withImage to={viewport5.googlePlayButton.url}>
            <GooglePlayBg />
          </ButtonDefault>
          {` `}
          <ButtonDefault withImage to={viewport5.appStoreButton.url}>
            <AppleStoreBg />
          </ButtonDefault>
        </Buttons>
      </FifthViewport>

      <SixthViewport>
        <Label>{viewport6.title}</Label>
        <HowItWorksCardWrap>
          {viewport6.howItWorksCards.map((item) => (
            <HowItWorksCard
              key={item.title}
              title={item.title}
              text={item.text}
              image={item.image}
            />
          ))}
        </HowItWorksCardWrap>
      </SixthViewport>
    </VpWrapper>
    <VpWrapperWhite>
      <FirstViewport imgWidth="50%" withRightPadding img={Vp7Image}>
        <SecondaryP className="accent-text-gray">{viewport7.label}</SecondaryP>
        <H1>
          <span className="accent-text">{viewport7.title1}</span>
          {` `}
          {viewport7.title2}
        </H1>
        <StyledStrong>
          {viewport7.description1}
          {` `}
          {viewport7.description2}
        </StyledStrong>
        <Description>
          <TickedItems items={viewport7.descriptionItems} />
        </Description>
      </FirstViewport>
      <FirstViewportMirrored imgWidth="50%" withRightPadding img={Vp6Image}>
        <H1>
          {viewport8.title1}
          <span className="accent-text"> {viewport8.title2}</span>
          {` `}
          {viewport8.title3}
        </H1>
        <StyledStrong>{viewport8.description}</StyledStrong>
        <DescriptionWrapper>
          <TickedItems items={viewport8.descriptionItems} />
        </DescriptionWrapper>
      </FirstViewportMirrored>
      <SeventhViewport>
        <CenterH1>{viewport9.title}</CenterH1>
        <VpDescription>{viewport9.description}</VpDescription>
        <HowToCardsWrap>
          {viewport9.howToCards.map((item) => (
            <HowToCard
              key={item.count}
              number={item.count}
              text={item.text}
              imageSrc={item.image}
            />
          ))}
        </HowToCardsWrap>
      </SeventhViewport>
      <EighthViewport>
        <Banner
          bgSrc={bannerBg}
          label={viewport10.bannerLabel}
          descriptionItems={viewport10.descriptionItems}
          buttonUrl={viewport10.button.url}
          buttonLabel={viewport10.button.label}
        />
      </EighthViewport>
    </VpWrapperWhite>
  </>
);

export default WholesalersLogisticContainer;
